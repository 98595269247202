import React, { useEffect, useState } from "react";

import classnames from "classnames";
import ChatLoader from "./chatLoader";

import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
} from "reactstrap";




interface LoadingModalProps {
  stage:any,
  setStage:any
 
  isOpen: boolean;
  onClose: () => void;
  
}
const UploadingModal = ({ isOpen, onClose,stage, setStage}: LoadingModalProps) => {

  const stageStyle = {
    marginLeft:'5px',    
   
    // You can add more CSS properties as needed
  };

    
  return (
    <Modal
      isOpen={isOpen}
      tabIndex={-1}
      centered
      className="contactModal"
    >
      <ModalHeader className="modal-title-custom">
      <p>Uploading Files<span style={stageStyle}>{`${stage}%`}</span></p></ModalHeader>
      <ChatLoader/>
   
    </Modal>

    
  );
};

export default UploadingModal;
