export enum UploadDocumentActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/uploadDocument/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/uploadDocument/API_RESPONSE_ERROR",
  
    UPLOAD_DOCUMENT = "@@auth/uploadDocument/UPLOAD_DOCUMENT",
  }
  
  export interface UploadDocumentState {
    registrationError: any;
    message: string;
    loading: boolean;
    assistant: object | null;
   }
  