import React from "react";
import { NavItem, UncontrolledTooltip, NavLink } from "reactstrap";
import Joyride from 'react-joyride';


//constants
import { LAYOUT_MODES } from "../constants/index";

interface LightDarkProps {
  layoutMode: any;
  onChangeLayoutMode: any;
}

var introMode = localStorage.getItem("introMode");
  
const steps = [
  {
    target: '.theme',
    content: 'Hive now allows you to switch between light and dark mode, click this button to switch modes ',

  },
 

]

const LightDarkMode = ({ layoutMode, onChangeLayoutMode }: LightDarkProps) => {

  const mode =
  layoutMode === LAYOUT_MODES["DARK"]
    ? LAYOUT_MODES["LIGHT"]
    : LAYOUT_MODES["DARK"];
    
  return (
  <>
    {introMode != 'OLD'? (<Joyride
          steps={steps}
           
          showSkipButton={true}
          styles={{
            options: {
              
              primaryColor: '#bb1763',
              
            }
          }}
        />):("")}
    <NavItem className="mt-auto" id="color-mode">
      <NavLink
        className="nav-link light-dark theme"
        onClick={() => onChangeLayoutMode(mode)} style={{cursor:'pointer'}}
      >
        <i className="bx bx-moon" id="moon"></i>{" "}
      </NavLink>{" "}
      <UncontrolledTooltip placement="right" target="color-mode">
        <span className="light-mode">Light</span>
        <span className="dark-mode">Dark</span> Mode{" "}
      </UncontrolledTooltip>{" "}
    </NavItem>
    </>
  );
};

export default LightDarkMode;
