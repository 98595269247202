import { takeEvery, fork, put, all, call } from "redux-saga/effects";
//Account Redux states
import { UploadDocumentActionTypes} from "./types";
import {
    uploadDocumentApiResponseSuccess,
    uploadDocumentApiResponseError,
} from "./actions";
import { beginDocumentUpload } from "../../helpers/uploadDocumentHelpers/beginDocumentUpload";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";


// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* uploadDocument({ payload: { uploadDetails } }: any) {
  try {
    
      const response: Promise<any> = yield call(
        beginDocumentUpload,
        uploadDetails.files,uploadDetails.index, uploadDetails.namespace,uploadDetails.assistantId, uploadDetails.setStage
      );


      yield put(
        uploadDocumentApiResponseSuccess(
           UploadDocumentActionTypes.UPLOAD_DOCUMENT,
          response
        )
      );
    
  } catch (error: any) {
    yield put(
        uploadDocumentApiResponseError(UploadDocumentActionTypes.UPLOAD_DOCUMENT, error)
    );
  }
}

export function* watchUploadDocument() {
  yield takeEvery(UploadDocumentActionTypes.UPLOAD_DOCUMENT, uploadDocument);
}

function* uploadDocumentSaga() {
  yield all([fork(watchUploadDocument)]);
}

export default uploadDocumentSaga;
