import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { getDocument } from "pdfjs-dist";
import { GlobalWorkerOptions } from "pdfjs-dist";

export const extractPDF = async (file) => {
  GlobalWorkerOptions.workerSrc =  "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/build/pdf.worker.min.js";

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = async () => {
      const buffer = reader.result;
      const typedArray = new Uint8Array(buffer);

      try {
        const pdf = await getDocument(typedArray).promise;
        const numPages = pdf.numPages;
        const extractedPages = new Map(); // Create a Map to store page numbers and extracted text

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map((item) => item.str).join(" ");
          extractedPages.set(i, pageText); // Use page number as the key in the Map
        }

        resolve(extractedPages);
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};
