export enum AddAssistantActionTypes {
    API_RESPONSE_SUCCESS = "@@auth/addAssistant/API_RESPONSE_SUCCESS",
    API_RESPONSE_ERROR = "@@auth/addAssistant/API_RESPONSE_ERROR",
  
    ADD_ASSISTANT = "@@auth/addAssistant/ADD_ASSISTANT",
  }
  
  export interface AddAssistantState {
    registrationError: any;
    message: string;
    loading: boolean;
    assistant: object | null;
   }
  