export const cleanBookPages = async (bookPages) => {
  function cleanText(text) {
    const cleanedText = text
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .toLowerCase()
      .replace(/\s+/g, " ")
      .trim();
    return cleanedText;
  }

  const cleanedMap = new Map();

  for (const [pageNum, value] of bookPages) {
    if (Array.isArray(value)) {
      const cleanedValue = value.map((page) => cleanText(page));
      cleanedMap.set(pageNum, cleanedValue);
    } else {
      const cleanedValue = cleanText(value);
      cleanedMap.set(pageNum, cleanedValue);
    }
  }

  return cleanedMap;
};
