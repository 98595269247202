import React, { useEffect } from "react";

// scss
import "./assets/scss/theme.scss";

// actions
import { useSelector, useDispatch } from "react-redux";
import { changelayoutMode } from "./redux/actions";

//Route
import Routes from "./routes";

//Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";

//api config
// import config from "./config";
import fakeBackend from "./helpers/fakeBackend";


// TODO
//fakeBackend();
const firebaseConfig = {
  apiKey: "AIzaSyCwelpCFXg26aJkK-FEvKpWkn-HfOaj4AI",
  authDomain: "hive-397914.firebaseapp.com",
  projectId: "hive-397914",
  storageBucket: "hive-397914.appspot.com",
  messagingSenderId: "245424519941",
  appId: "1:245424519941:web:48b970d4d4a4c4e11a156c",
  measurementId: "G-HFN9C505TY"
};



// // init firebase backend
initFirebaseBackend(firebaseConfig);

const App = () => {
  const dispatch = useDispatch();

  const { layoutMode } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  // Dark/Light Mode 
  useEffect(() => {
    var getLayoutMode = localStorage.getItem("layoutMode");
    if (getLayoutMode) {
      dispatch(changelayoutMode(getLayoutMode));
    } else { 

      dispatch(changelayoutMode(layoutMode));}
   
  }, [layoutMode, dispatch]);

  return <Routes />;
};

export default App;
