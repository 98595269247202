export const upsertVectors = async (index, namespace, data) => {
  const batchSize = 500; // Set the desired batch size (you can adjust this as needed).

  try {
    // Split the data into smaller chunks (batches).
    for (let i = 0; i < data.length; i += batchSize) {
      const chunk = data.slice(i, i + batchSize);

      // Send a POST request to the search API with the current batch.
      const response = await fetch('https://us-central1-hive-397914.cloudfunctions.net/upsertVectors', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ index: index, namespace: namespace, data: chunk }),
      });

      // Convert the response to JSON.
      const jsonResponse = await response.json();
      console.log(jsonResponse);
    }

    // All chunks have been uploaded successfully.
    console.log('All data chunks uploaded.');

  } catch (error) {
    // TODO: Handle errors.
    console.log(error);
  }
};

