import {  AddAssistantActionTypes,  AddAssistantState } from "./types";

export const INIT_STATE: AddAssistantState = {
  registrationError: null,
  message: "",
  loading: false,
  assistant: null,
  
 };

const AddAssistant = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AddAssistantActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AddAssistantActionTypes.ADD_ASSISTANT:
          return {
            ...state,
            loading: false,
            assistant: action.payload.data,
            registrationError: null,
            isUserRegistered: true,
          };
        default:
          return { ...state };
      }

    case AddAssistantActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AddAssistantActionTypes.ADD_ASSISTANT:
          return {
            ...state,
            loading: false,
            registrationError: action.payload.error,
            isUserRegistered: false,
          };
        default:
          return { ...state };
      }

    case AddAssistantActionTypes.ADD_ASSISTANT: {
      
      return {
        ...state,
        loading: true,
        
        
      };
    }
    default:
      return { ...state };
  }
};

export default AddAssistant;
