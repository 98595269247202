import { AddAssistantActionTypes } from "./types";

// common success
export const addAssistantApiResponseSuccess = (
  actionType: string,
  data: any
) => {
  console.log("success", data)

  return {
  type: AddAssistantActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
  }

};

// common error
export const addAssistantApiResponseError = (
  actionType: string,
  error: string
)=> {
  console.log("error", error)
  return {
  type: AddAssistantActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
  }};

export const addAssistant = (assistant: any) => {
  console.log("data sent", assistant)

  return {
    type: AddAssistantActionTypes.ADD_ASSISTANT,
    payload: { assistant },
  };
};
