import React, { useEffect, useRef, useCallback, useState } from "react";
import { useRedux } from "../../../../hooks/index";
import { Input } from "reactstrap";
import ChatLoader from "../../../../components/chatLoader";

interface InputSectionProps {
  value: null | string;
  onChange: (value: string) => void;
}



const InputSection = ({ value, onChange }: InputSectionProps) => {
  const { dispatch, useAppSelector } = useRedux();

const { getUserConversationsLoading } = useAppSelector(
  (state: any) => ({
    getUserConversationsLoading: state.Chats.getUserConversationsLoading,
     })
);
  return (
    <div className="position-relative">
            {getUserConversationsLoading && <ChatLoader/>}
      <Input
        type="text"
        className="form-control form-control-lg chat-input"
        id="chat-input"
        placeholder="Type your message..."
        value={value || ""}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        autoComplete="off"
      />
    </div>
  );
};
export default InputSection;
