import {  UploadDocumentActionTypes,  UploadDocumentState } from "./types";

export const INIT_STATE: UploadDocumentState = {
  registrationError: null,
  message: "",
  loading: false,
  assistant: null,
 };

const UploadDocument = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case UploadDocumentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case UploadDocumentActionTypes.UPLOAD_DOCUMENT:
          return {
            ...state,
            loading: false,
            assistant: action.payload.data,
            registrationError: null,
            isUserRegistered: true,
          };
        default:
          return { ...state };
      }

    case UploadDocumentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UploadDocumentActionTypes.UPLOAD_DOCUMENT:
          return {
            ...state,
            loading: false,
            registrationError: action.payload.error,
            isUserRegistered: false,
          };
        default:
          return { ...state };
      }

    case UploadDocumentActionTypes.UPLOAD_DOCUMENT: {
      
      return {
        ...state,
        loading: true,
        
        
      };
    }
    default:
      return { ...state };
  }
};

export default UploadDocument;
