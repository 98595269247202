import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import Joyride from 'react-joyride';


//components
import AddButton from "../../../components/AddButton";

// interface
import { UserTypes } from "../../../data/chat";

// component
import ChatUser from "./ChatUser";

interface DirectMessagesProps {
  users: Array<UserTypes>;
  openAddContact: () => void;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const DirectMessages = ({
  users,
  openAddContact,
  selectedChat,
  onSelectChat,
}: DirectMessagesProps) => {
  /*
    add contacts
    */

    var introMode = localStorage.getItem("introMode");

    const steps = [
      {
        target: '.first',
        content: 'Welcome to Hive version 2, lets get you up to speed on the new features, click next to continue ',
        disableBeacon: true,
      },
      {
        target: '.second',
        content: 'You can now create your own assistants, press this button to add and name your assistant, next click the red glowing icon at your bottom left side of the app to check out our next new feature',
        disableBeacon: true,
      },
    
    ]
  

  return (
    <>
   {introMode != 'OLD'? (<Joyride
          steps={steps}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          styles={{
            options: {
              
              primaryColor: '#bb1763',
              
            }
          }}
        />):("")}
      <div className="d-flex align-items-center px-4 mt-5 mb-2">
        <div className="flex-grow-1">
          <h4 className="mb-0 font-size-11 text-muted text-uppercase">
            All Assistants
          </h4>
        </div>
        <div className="flex-shrink-0">
          <div id="new-message" title="" className="first second">
          
            <AddButton onClick={openAddContact} /> 
          </div>
          <UncontrolledTooltip target="new-message" placement="bottom">
            Add Assistant
          </UncontrolledTooltip>
        </div>
      </div>
      

      <div className="chat-message-list">
        <ul className="list-unstyled chat-list chat-user-list">
          {(users || []).map((user: UserTypes, key: number) => (
            <ChatUser
              user={user}
              key={key}
              selectedChat={selectedChat}
              onSelectChat={onSelectChat}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

export default DirectMessages;
