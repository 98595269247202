import { startExtraction } from "./startExtraction";
import { processDocuments } from "./processDocuments";
import { splitKnowledgeBase } from "./splitKnowledgeBase";
import { createVectorEmbeddings } from "./createVectorEmbeddings";
import { prepareVectorEmbeddings } from "./prepareVectorEmbeddings";
import { upsertVectors } from "./upsertVectors";
import { getFirebaseBackend
 } from "../firebase_helper";
export const beginDocumentUpload = async (e, index, namespace, assistantId,setStage) => {

  const fireBaseBackend = getFirebaseBackend();

  const knowledgeBaseMap = await startExtraction(e);
  setStage("10");
  console.log(knowledgeBaseMap);
  const processedKnowledgeMap = await processDocuments(knowledgeBaseMap);
  setStage("20");
  const splitKnowledgeBaseResult = await splitKnowledgeBase(processedKnowledgeMap);
  setStage("40");
  const vectorEmbeddings = await createVectorEmbeddings(splitKnowledgeBaseResult);
  setStage("60");
  const readyVectorData = await prepareVectorEmbeddings(vectorEmbeddings,splitKnowledgeBaseResult);
  setStage("70");
  const upsertResult = await upsertVectors(index, namespace, readyVectorData);
  setStage("90");
  await fireBaseBackend.updateKnowledgeBaseList([...e.target.files], assistantId);
  setStage("100");
};
