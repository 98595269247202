export const splitKnowledgeBase = async (map) => {
  const data = [];

  // Process each item in the array
  for (const [key, value] of map.entries()) {
    const contentArray = value.content;

    // Extract the content from the nested array
    const content = [];
    for (const contentItem of contentArray) {
      content.push(contentItem);
    }

    data.push({
      key: key,
      content: content,
      name: value.name,
      numPages: value.numPages,
      totalTokens: value.totalTokens,
    });
  }

  try {
    // Send a POST request to the search API with the source and search keywords.
    const response = await fetch(
      "https://us-central1-ornate-node-386212.cloudfunctions.net/splitKnowledgeBase-1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    );

    // Convert the response to JSON.
    //const originalData = { data: data };
    const jsonResponse = await response.json();
    console.log(jsonResponse);
    return jsonResponse;
  } catch (error) {
    // TODO: Handle errors.
    console.log(error);
  }
};
