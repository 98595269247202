// types
import { SettingsActionTypes, SettingsState } from "./types";

export const INIT_STATE: SettingsState = {
  settings: {
    basicDetails: {
      firstName: "",
      lastName: "",
      profile:'https://firebasestorage.googleapis.com/v0/b/hive-50f55.appspot.com/o/empty_user.png?alt=media&token=ac78c97a-050e-413e-b7e0-c370f959eee9',
      coverImage: '',
      email:"",
      location: "",
    },
    theme: {
      // color: "bgcolor-radio1",
      image: "bgimg-radio5",
    },
    privacy: {
      displayprofilePhoto: "selected",
      displayLastSeen: true,
      displayStatus:'',
      readReceipts: true,
      displayGroups:'',
    },
    security: {
      securityNotification: false,
    },
    status:'',
  },
};

const Settings = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case SettingsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case SettingsActionTypes.GET_USER_SETTINGS:
          return {
            ...state,
            settings: action.payload.data,
            isSettingsFetched: true,
            getSettingsLoading: false,
            isSettingsUpdated: false,
          };
        case SettingsActionTypes.UPDATE_USER_SETTINGS:
          return {
            ...state,
            isSettingsUpdated: true,
          };
        default:
          return { ...state };
      }

    case SettingsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SettingsActionTypes.GET_USER_SETTINGS:
          return {
            ...state,
            isSettingsFetched: false,
            getSettingsLoading: false,
          };
        case SettingsActionTypes.UPDATE_USER_SETTINGS:
          return {
            ...state,
            isSettingsUpdated: false,
          };
        default:
          return { ...state };
      }

    case SettingsActionTypes.GET_USER_SETTINGS: {
      return {
        ...state,
        getSettingsLoading: true,
        isSettingsFetched: false,
      };
    }
    case SettingsActionTypes.UPDATE_USER_SETTINGS:
      return {
        ...state,
        isSettingsUpdated: false,
      };

    default:
      return { ...state };
  }
};

export default Settings;
