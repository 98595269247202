import React from "react";
import { Button, Col, Row } from "reactstrap";

const Welcome = () => {
  return (
    <React.Fragment>
      <div className="chat-welcome-section "style={{ paddingTop: '15%' }}>
        <Row className="w-100 justify-content-center overflow-auto pb-0">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
               <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title bg-soft-primary rounded-circle">
                  <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
                </div>
              </div>
             <h4>Welcome to Hive Messenger</h4>
              <p className="text-muted mb-4">
               To get started, simply select your chat on the left, if your new click the button below to get started
              </p>
              <Button type="button" className="btn btn-primary w-lg">
                Get Started
            </Button> 
            {/*
              <div className="container mt-5">
              <div  className="col-sm justify-content-center mb-5" style={{cursor: "pointer"}}>
     <h4 >Examples</h4>
     <div className="grey-container"style={{ marginTop: '2%' }}>
      <p>1. What is my module about and can you list units available?</p>
      <p>2. Tell me about learning unit 7.</p>
    </div>
    </div>
  <div className="row mt-10 justify-content-center">
    
    <div className="col-sm" style={{cursor: "pointer"}}>
    <h4>Capabilities</h4>
    <div className="grey-container " style={{ marginTop: '4%' }} >
      <p>1. Has instant access to your entire knowledge base.</p>
     <p> 2. Keeps track of your conversation history.</p>
    </div>
    </div>
    <div className="col-sm" style={{cursor: "pointer"}}>
      <h4>Limitations</h4>
      <div className="grey-container" style={{ marginTop: '4%' }} >
      <p>1. Cannot output table data yet, please use lists instead for now.</p>
      <p>2. If you do not get the response you want dont worry, simply be more specific</p>
    </div>
    </div>
  </div>
</div>*/}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
