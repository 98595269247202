import React from "react";
const ChatLoader = () => {
  return (
    <div className="chat-loader-container">
      <div className="status">
      <div className="loader-line">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
      </div>
    </div>
  );
};

export default ChatLoader;
