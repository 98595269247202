import { UploadDocumentActionTypes } from "./types";

// common success
export const uploadDocumentApiResponseSuccess = (
  actionType: string,
  data: any
) => {
  console.log("Data uploaded Succesfully")

  return {
  type: UploadDocumentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
  }

};

// common error
export const uploadDocumentApiResponseError = (
  actionType: string,
  error: string
)=> {
  console.log("error", error)
  return {
  type: UploadDocumentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
  }};

export const uploadDocument = (uploadDetails: any) => {
  console.log("data upload data", uploadDetails)

  return {
    type: UploadDocumentActionTypes.UPLOAD_DOCUMENT,
    payload: { uploadDetails },
  };
};
