import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { AddAssistantActionTypes } from "./types";
import {
  addAssistantApiResponseSuccess,
  addAssistantApiResponseError,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";


// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* addAssistant({ payload: { assistant } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response: Promise<any> = yield call(
        fireBaseBackend.addAssistant,
        assistant.name,
        
      );
      yield put(
        addAssistantApiResponseSuccess(
            AddAssistantActionTypes.ADD_ASSISTANT,
          response
        )
      );
    }
  } catch (error: any) {
    yield put(
        addAssistantApiResponseError(AddAssistantActionTypes.ADD_ASSISTANT, error)
    );
  }
}

export function* watchAddAssistant() {
  yield takeEvery(AddAssistantActionTypes.ADD_ASSISTANT, addAssistant);
}

function* addAssistantSaga() {
  yield all([fork(watchAddAssistant)]);
}

export default addAssistantSaga;
