/**
 * Generates a random string ID with 20 characters
 *
 * @returns {string} The generated ID
 */
export const generateBookId = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const idLength = 20;
    let id = "";
  
    // generate each character of the ID randomly from the given characters
    for (let i = 0; i < idLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters.charAt(randomIndex);
    }
  
    return id;
  };
  