import React, { useEffect, useState } from "react";

import { getLoggedinUser } from "../../api/apiCore";

import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";

// actions
import { changeTab } from "../../redux/actions";

// costants
import { TABS } from "../../constants/index";
import LightDarkMode from "../../components/LightDarkMode";

//images
import avatar1 from "../../assets/images/users/avatar-1.jpg";

import NewLogo from "../../assets/images/logo.png";

import { getSettings, updateSettings } from "../../../src/redux/actions";

// interface
import { SettingsTypes } from "../../../src/data/settings";


// menu
import { MENU_ITEMS, MenuItemType } from "./menu";



const LogoLightSVG = () => {
  return (
    <img src={NewLogo} style={{ width: "40px", height: "40px" }}/>
  );
};

const LogoDarkSVG = () => {
  return (

    <img src={NewLogo} style={{ width: "40px", height: "40px" }}/>
    
  );
};

const Logo = () => {
  return (
    <div className="navbar-brand-box">
      <Link to="#" className="logo logo-dark">
        <span className="logo-sm">
          <LogoLightSVG />
        </span>
      </Link>

      <Link to="#" className="logo logo-light">
        <span className="logo-sm">
          <LogoDarkSVG />
        </span>
      </Link>
    </div>
  );
};

interface MenuNavItemProps {
  item: MenuItemType;
  selectedTab:
    | TABS.BOOKMARK
    | TABS.CALLS
    | TABS.CHAT
    | TABS.CONTACTS
    | TABS.SETTINGS
    | TABS.USERS;
  onChangeTab: (
    id:
      | TABS.BOOKMARK
      | TABS.CALLS
      | TABS.CHAT
      | TABS.CONTACTS
      | TABS.SETTINGS
      | TABS.USERS
  ) => void;
}
const MenuNavItem = ({ item, selectedTab, onChangeTab }: MenuNavItemProps) => {
  const onClick = () => {
    onChangeTab(item.tabId);
  };
  return (
    <>
      <NavItem className={item.className} id={`${item.key}-container`}>
        <NavLink
          href="#"
          active={selectedTab === item.tabId}
          id={item.key}
          role="tab"
          onClick={onClick}
        >
          <i className={item.icon}></i>
        </NavLink>
      </NavItem>
      <UncontrolledTooltip target={`${item.key}-container`} placement="right">
        {item.tooltipTitle}
      </UncontrolledTooltip>
    </>
  );
};

interface ProfileDropdownMenuProps {
  onChangeTab: (
    id:
      | TABS.BOOKMARK
      | TABS.CALLS
      | TABS.CHAT
      | TABS.CONTACTS
      | TABS.SETTINGS
      | TABS.USERS
  ) => void;
}
const ProfileDropdownMenu = ({ onChangeTab }: ProfileDropdownMenuProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const { dispatch, useAppSelector } = useRedux();

const { settingsData, getSettingsLoading } = useAppSelector(state => ({
  settingsData: state.Settings.settings,
  getSettingsLoading: state.Profile.getSettingsLoading,
  isSettingsFetched: state.Profile.isSettingsFetched,
}));


  // get user settings
  useEffect(() => {
    console.log('running dispatch');
    dispatch(getSettings(getLoggedinUser().uid));
  }, [dispatch]);

  const [settings, setSettings] = useState<SettingsTypes>(settingsData);
  useEffect(() => {
    setSettings(settingsData);
  }, [settingsData]);

  return (
    <Dropdown
      nav
      isOpen={dropdownOpen}
      className="profile-user-dropdown"
      toggle={toggle}
    >
      <DropdownToggle nav className=" bg-transparent">
        <img src={settings.basicDetails.profile} alt="" className="profile-user rounded-circle" />
      </DropdownToggle>
      <DropdownMenu>
        {/*<DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onChangeTab(TABS.USERS)}
        >
          Profile <i className="bx bx-user-circle text-muted ms-1"></i>
        </DropdownItem> */}
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => onChangeTab(TABS.SETTINGS)}
        >
          Settings <i className="bx bx-cog text-muted ms-1"></i>
        </DropdownItem>
        {/*<DropdownItem
          className="d-flex align-items-center justify-content-between"
          href="/auth-changepassword"
        >
          Change Password <i className="bx bx-lock-open text-muted ms-1"></i>
        </DropdownItem>*/}

        <DropdownItem />
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          tag="a"
          href="/logout"
        >
          Log out <i className="bx bx-log-out-circle text-muted ms-1"></i>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const SideMenu = ({ onChangeLayoutMode }: any) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const menuItems: MenuItemType[] = MENU_ITEMS;
  const { activeTab, layoutMode } = useAppSelector(state => ({
    activeTab: state.Layout.activeTab,
    layoutMode: state.Layout.layoutMode,
  }));

  /* 
    tab activation
    */
  const [selectedTab, setSelectedTab] = useState<
    | TABS.BOOKMARK
    | TABS.CALLS
    | TABS.CHAT
    | TABS.CONTACTS
    | TABS.SETTINGS
    | TABS.USERS
  >(TABS.CHAT);
  const onChangeTab = (
    id:
      | TABS.BOOKMARK
      | TABS.CALLS
      | TABS.CHAT
      | TABS.CONTACTS
      | TABS.SETTINGS
      | TABS.USERS
  ) => {
    setSelectedTab(id);
    dispatch(changeTab(id));
  };

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  return (
    <div className="side-menu flex-lg-column">
      {/* LOGO */}
      <Logo />
      {/* end navbar-brand-box */}

      {/* Start side-menu nav */}
      <div className="flex-lg-column my-0 sidemenu-navigation">
        <Nav pills className="side-menu-nav" role="tablist">
          {(menuItems || []).map((item: MenuItemType, key: number) => (
            <MenuNavItem
              item={item}
              key={key}
              selectedTab={selectedTab}
              onChangeTab={onChangeTab}
            />
          ))}

           
          <LightDarkMode
            layoutMode={layoutMode}
            onChangeLayoutMode={onChangeLayoutMode}
          /> 

          {/* profile menu dropdown */}
          <ProfileDropdownMenu onChangeTab={onChangeTab} />
        </Nav>
      </div>
      {/* end side-menu nav */}
    </div>
  );
};

export default SideMenu;
