import React, { useState, useEffect } from "react";
import { extractPDF } from "./extractPdf";
import { cleanBookPages } from "./cleanBookPages";
import { generateBookId } from "./generateBookId";

export const startExtraction = async (e) => {
  const extractedPagesMap = new Map();

  try {
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const extractedPages = await extractPDF(file);
      const fileTitle = file.name;

      const cleanedDataMap = await cleanBookPages(extractedPages);

      // Generate alphanumeric key using generateBookId() function
      const key = generateBookId();

      // Calculate the number of pages and total tokens
      const numPages = cleanedDataMap.size;
      let totalTokens = 0;

      // Create the content map containing the cleaned data
      const content = new Map();

      for (const [pageNum, pageContent] of cleanedDataMap.entries()) {
        const words = pageContent.trim().split(/\s+/); // Split the page into words
        totalTokens += words.length; // Add the number of words to the totalTokens
        content.set(
          pageNum,
          `<<<<This Information was taken from the following book name:${fileTitle} and the following page number:${pageNum}>>>> ${pageContent}`
        );
      }

      // Create book details object
      const bookDetails = {
        name: fileTitle,
        numPages,
        totalTokens,
        content,
      };

      // Add book details to the map with the generated key
      extractedPagesMap.set(key, bookDetails);

      //const book = extractedPagesMap.get(key);

      //const contents = book.content;

      //const page = contents.get(51);
      console.log(extractedPagesMap); // Check the value
    }
    return extractedPagesMap;
  } catch (error) {
    console.error("Error extracting pages from PDF:", error);
  }
};
