export const prepareVectorEmbeddings = async (data, contentData) => {
  console.log("Here is data",data);
  console.log("Here is contentData",contentData);
  const finalVectors = [];

  for (let [index, item] of data.data.entries()) {
    let bookKey = item.key;
    let bookName = item.name;

    let contentDict = item.content;

    for (let [contentKey, contentValue] of Object.entries(contentDict)) {
      const vector = {
        id: `${bookKey}-${contentKey}`,
        values: contentValue,
        metadata: {
          book_key: bookKey,
          book_name: bookName,
          content: contentData.data[index].content[contentKey],
        },
      };

      finalVectors.push(vector);
    }
  }

  console.log("here are final vectors",finalVectors);

  return finalVectors;
};
