export const processDocuments = async (extractedPagesMap) => {
  var finalMap = extractedPagesMap;

  finalMap.forEach((bookDetails, key) => {
    var newArray = [];

    bookDetails.content.forEach((value, key) => {
      newArray.push(value);
    });

    bookDetails.content = newArray;
  });

  return finalMap;
};
