import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getLoggedinUser } from "../api/apiCore";



import mixpanel from 'mixpanel-browser'

// Uncomment if you used npm or yarn in Step 1
 
mixpanel.init('626a4d1b29c56b0b6305f00b3963a517', { debug: true, track_pageview: false, persistence: 'localStorage' });

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setLoggeedInUser(user);
          mixpanel.identify(user.uid);
          mixpanel.track('Messenger Login', {
            'Login Type': 'Auto Login on Messenger'
          })


        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /*Retrieves and sets settigns from firebase    */

 /**
   * Registers the user with given details
   */
  registerUser = (email, password, username) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            let userL = this.addNewSimpleUserToFirestore(user, email, username);
            userL = JSON.stringify(userL);
            resolve(userL);
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  updateKnowledgeBaseList = async (fileArray, assistantId) => {
    const db = firebase.firestore(); // Get Firestore instance
    const collectionRef = db.collection('assistants'); // Reference to the collection

    try {
      // Query for the assistant with the given assistantId
      const querySnapshot = await collectionRef.where('assistantId', '==', assistantId).get();

      if (querySnapshot.empty) {
        console.error('Document not found.');
        return false;
      }

      const docSnapshot = querySnapshot.docs[0]; // Get the first document
      const currentData = docSnapshot.data().media || []; // Get current knowledgeBase data

      function createFileMap(file, index) {
        const sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

        const fileMap = {
          id: currentData.length + index,
          fileName: file.name,
          size: sizeInMB + ' MB',
          downloadUrl: '#',
          icon: 'bx bx-file',
        };

        return fileMap;
      }

      function processFiles(fileArray) {
        const fileMapsArray = [];
        fileArray.forEach((file, index) => {
          const fileMap = createFileMap(file, index);
          fileMapsArray.push(fileMap);
        });

        return fileMapsArray;
      }

      const fileMapsArray = processFiles(fileArray);
      const updatedData = [...currentData, ...fileMapsArray]; // Combine existing and new data

      // Update the document in Firestore with the updated knowledgeBase field
      await collectionRef.doc(docSnapshot.id).update({ media: updatedData });

      return true;
    } catch (error) {
      console.error('Error updating document:', error);
      return false;
    }
  }; 

  

  addAssistant = (name) => {
    const collection = firebase.firestore().collection("assistants");
  
    
  
    const generatedId = collection.doc().id; // Generate a random ID

    const details = {
      assistantId: generatedId,
      user_id: getLoggedinUser().uid,
      purpose: "You help Students with their courses",
      template_id: "course_tutor",
      name: name,
      media:[],
      knowledge_base_index: "prometheus",
      knowledge_base_namespace:  getLoggedinUser().uid  + "-" + generatedId 

    };
  
    collection.doc(generatedId).set(details);
  
    return { generatedId, details };
  };
  
  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {

            const uid = user.user.uid;

            mixpanel.track('Messenger Login', {
              'Login Type': 'Messenger Self Login'
            })
            
            const currentUser = JSON.stringify(firebase.auth().currentUser);
            resolve(currentUser);
          
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {};
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(
        data.idToken,
        data.token
      );
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token);
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase
          .auth()
          .signInWithCredential(credential)
          .then((user) => {
            let userL = this.addNewUserToFirestore(user);
            userL = JSON.stringify(userL);
            resolve(userL);
          })
          .catch((error) => {
            reject(this._handleError(error));
          });
      } else {
        // reject(this._handleError(error));
      }
    });
  };

  addNewUserToFirestore = (user) => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp(),
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  addNewSimpleUserToFirestore = (user, email, username) => {
    const collection = firebase.firestore().collection("users");

    const details = {
      email: email,
    
      firstName: username,
      creditBalance: 200,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      profileImg:'https://firebasestorage.googleapis.com/v0/b/hive-50f55.appspot.com/o/empty_user.png?alt=media&token=ac78c97a-050e-413e-b7e0-c370f959eee9'
    
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

const setLoggeedInUser = (user) => {
  localStorage.setItem("authUser", JSON.stringify(user));
};

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend, setLoggeedInUser };
