import cloneDeep from 'lodash/cloneDeep';

export const createVectorEmbeddings = async (data) => {
  var finalData = cloneDeep(data);
  var processData = cloneDeep(data);
  

  const chunkArray = async (array, chunkSize) => {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunkedArray.push(array.slice(i, i + chunkSize));
    }
    return chunkedArray;
  };

  const embeddingsApi = async (array) => {
    try {
      // Send a POST request to the search API with the source and search keywords.
      const response = await fetch(
        "https://us-central1-ornate-node-386212.cloudfunctions.net/createVectorEmbeddings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: array,
          }),
        }
      );

      // Convert the response to JSON.
      // const originalData = { data: data };
      const jsonResponse = await response.json();

      return jsonResponse;
    } catch (error) {
      // TODO: Handle errors.
      console.log(error);
    }
  };

  const prepareToVectorize = async (dataMap,finalDataInput) => {
   // console.log('check this out',dataMap);
    for (const [bookIndex, book] of dataMap.data.entries()) {
      var finalContentArray = [];

      const bookKey = book.key;
      const content = book.content;
      // console.log("Book Key:", bookKey);
      //console.log("Content:", content);

      const chunkedArray = await chunkArray(content,20);

      for (const array of chunkedArray) {
        var result = await embeddingsApi(array);

        finalContentArray.push(...result);
      }

      finalDataInput.data[bookIndex].content = finalContentArray;
    }
  };

  await prepareToVectorize(processData,finalData);
  console.log("uploaded all chunks");

  return finalData;

  /*
   */
};
