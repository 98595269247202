import { format } from "date-fns";

const formateDate = (date: any, formatString?: string) => {
  const dateObj = new Date(date);
  const dateFormat = formatString ? formatString : "MM/dd/yyyy";
  const formattedDate = format(dateObj, `EEEE, ${dateFormat}`);
  return formattedDate;
};

export { formateDate };
